import React, { FC, useRef, useCallback } from 'react';
import { Link } from 'react-scroll';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import {
  ANCHOR_LINKS_SETTINGS,
  ANCHOR_LINKS_MOBILE_OFFSET,
  ANCHOR_LINKS_DESKTOP_OFFSET,
} from 'utils/constants';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { ISubMenuItem } from './models';

const SubMenuItem: FC<ISubMenuItem> = ({ name, linkToSection, setIsOpen }) => {
  const { isMobile } = useScreenRecognition();
  const settings = {
    ...ANCHOR_LINKS_SETTINGS,
    to: linkToSection,
    offset: isMobile ? ANCHOR_LINKS_MOBILE_OFFSET : ANCHOR_LINKS_DESKTOP_OFFSET,
    onSetActive: () => setIsOpen(false),
  };
  const thisRef = useRef<React.ReactElement | any>(null);
  const handleEnterKey = useCallback((e) => {
    const link = thisRef?.current?.getElementsByTagName('a')?.[0] || null;
    link && e.key === 'Enter' && link.click();
  }, []);

  return (
    <li>
      <div role="button" tabIndex={0} ref={thisRef} onKeyPress={handleEnterKey}>
        <Link {...settings} data-title={name}>
          <DangerouslySetInnerHtml element="span" html={name} />
        </Link>
      </div>
    </li>
  );
};

export default SubMenuItem;
